@font-face {
    font-family: "unineue";
    src: local('unineue');
    src: url('../../fonts/Fontfabric\ -\ UniNeueThin.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: "unineue";
    src: local('unineue');
    src: url('../../fonts/Fontfabric\ -\ UniNeueLight.otf') format('opentype');
    font-weight: 200;
}

@font-face {
    font-family: "unineue";
    src: local('unineue');
    src: url('../../fonts/Fontfabric\ -\ UniNeueRegular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: "unineue";
    src: local('unineue');
    src: url('../../fonts/Fontfabric\ -\ UniNeueHeavy.otf') format('opentype');
    font-weight: 700;
}