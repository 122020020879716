#outlined-multiline-flexible::-webkit-scrollbar,
#chat-list::-webkit-scrollbar,
#voice-chat-list::-webkit-scrollbar,
#videosContainer::-webkit-scrollbar{
  width: 0px; 
  background: transparent; 
}
.inputs,
.labelAndSelect{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.labelAndSelect{
  margin-bottom: .7em;
}
.labelAndSelect select{
  min-height: 32px;
  border-radius: 4px;
  outline: 1px solid #E6E6E6;
  border: none;
}
.labelAndSelect:last-child{
   margin-bottom: 1.5em;
}
.labelAndSelect label{
  font-size: 1.1em;
  font-weight: bold;
  font-family: serif, 'Roboto';
color: #262626;
}