.activeLink{
	background-color: #bcd3f1;
}
.internalVideo{
	display: none;
	height: 200px;
	width: 250px;
	margin: 1em
}
.externalVideo{
	height: 500px;
	width: 600px;
}